import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { vars } from '@seed-design/design-token'
import { AppScreen } from '@stackflow/plugin-basic-ui'
import { useStack } from '@stackflow/react'

import { reqEmailCode, reqUpdateEmail, resetEmailState } from '@src/ducks/email'
import { validateEmailAddress, validateEmailValidationCode } from '@src/utils/validation'
import { emailInfoSelector } from '@src/selectors/email'
import Timer from '@src/components/base/Timer'
import { useQuery } from '@src/hooks/useQuery'
import { useActionState } from '@src/hooks/useActionState'
import Loader from '@src/components/base/Loader'
import { SCHEME_PREFIX } from '@src/config'
import { useCheckTimer } from '@src/hooks/useCheckTimer'
import decoratorEmail from '@src/assets/images/decorator_email.svg'
import { bridge } from '@src/bridge'
import { useDispatchWithHistory } from '@src/hooks/useDispatchWithHistory'
import { focusOn } from '@src/utils/focus'
import withOS from '@src/hocs/withOS'
import { body1 } from '@src/styles/text'
import IconBack from '@src/components/svg/IconBack'
import { countrySelector } from '@src/selectors/app'

const NAVBAR_HEIGHT_REM = '3.5rem'
const BOTTOM_BUTTON_HORIZONTAL_MARGIN_PX = '16px'
const BOTTOM_BUTTON_HEIGHT_PX = '55px'
const __DANGEROUS_COVER_UNKNOWN_MARGIN_PX = '12px'
const __DANGEROUS_TRANSLATE_Y_PX = 15

interface FormValues {
  email: string
  code: string
}

const EmailPage: React.FC = (props) => {
  const { globalTransitionState } = useStack()
  const { t } = useTranslation()
  const country = useSelector(countrySelector)
  const emailInfo = useSelector(emailInfoSelector)
  const dispatch = useDispatchWithHistory()
  const formik = useFormik<FormValues>({
    initialValues: { email: '', code: '' },
    onSubmit: () => {},
    validateOnChange: false,
  })
  const timerRef = useRef(null)
  const emailRef = useRef<HTMLInputElement>(null)
  const codeRef = useRef<HTMLInputElement>(null)
  const query = useQuery()
  const [enhancedReqEmailCode, codeAsyncState] = useActionState(reqEmailCode)
  const [enhancedReqUpdateEmail, emailAsyncState] = useActionState(reqUpdateEmail)
  const { isEnd, triggerTimer } = useCheckTimer()

  const [keyboardHeight, setKeyboardHeight] = useState(window.outerHeight - window.visualViewport.height)
  const [viewportHeight, setViewportHeight] = useState(-1)
  const [translateY, setTranslateY] = useState(0)
  const [keyboardActivateStatus, setKeyboardActivateStatus] = useState<'idle' | 'by-top' | 'by-bottom'>('idle')

  const emailGuideFaqScheme = useMemo(() => {
    if (country === 'CA') {
      return `${SCHEME_PREFIX}://minikarrot/router?remote=https%3A%2F%2Fca.karrotmarket.com%2Fwv%2Ffaqs%2Fslug%2Femail&navbar=false&scrollable=false`
    }

    if (country === 'US') {
      return `${SCHEME_PREFIX}://minikarrot/router?remote=https%3A%2F%2Fus.karrotmarket.com%2Fwv%2Ffaqs%2Fslug%2Femail&navbar=false&scrollable=false`
    }

    return `${SCHEME_PREFIX}://web/wv/faqs/529?present=top`
  }, [country])

  const authCodeFaqScheme = useMemo(() => {
    if (country === 'CA') {
      return `${SCHEME_PREFIX}://minikarrot/router?remote=https%3A%2F%2Fca.karrotmarket.com%2Fwv%2Ffaqs%2Fslug%2Femail_verificaiton&navbar=false&scrollable=false`
    }

    if (country === 'US') {
      return `${SCHEME_PREFIX}://minikarrot/router?remote=https%3A%2F%2Fus.karrotmarket.com%2Fwv%2Ffaqs%2Fslug%2Femail_verificaiton&navbar=false&scrollable=false`
    }

    return `${SCHEME_PREFIX}://web/wv/faqs/364?present=top`
  }, [country])

  useEffect(() => {
    const emailInput = emailRef.current
    if (!emailInput) return

    const decreaseViewportHeight = () => {
      setKeyboardActivateStatus('by-top')
    }
    const increaseViewportHeight = () => {
      setTimeout(() => {
        setKeyboardActivateStatus('idle')
      }, 0)
    }

    emailInput.addEventListener('focus', decreaseViewportHeight)
    emailInput.addEventListener('blur', increaseViewportHeight)

    return () => {
      if (!emailInput) return
      emailInput.removeEventListener('focus', decreaseViewportHeight)
      emailInput.removeEventListener('blur', increaseViewportHeight)
    }
  }, [])

  useEffect(() => {
    const code = codeRef.current
    if (!code) return

    const decreaseViewportHeight = () => {
      setKeyboardActivateStatus('by-bottom')
    }
    const increaseViewportHeight = () => {
      setKeyboardActivateStatus('idle')
    }

    code.addEventListener('focus', decreaseViewportHeight)
    code.addEventListener('blur', increaseViewportHeight)

    return () => {
      if (!code) return
      code.removeEventListener('focus', decreaseViewportHeight)
      code.removeEventListener('blur', increaseViewportHeight)
    }
  }, [])

  useEffect(() => {
    const resizeHandler = () => {
      setViewportHeight(window.visualViewport.height)
      const keyboardHeight = window.outerHeight - window.visualViewport.height
      setKeyboardHeight(keyboardHeight)
    }
    window.visualViewport.addEventListener('resize', resizeHandler)
    return () => {
      window.visualViewport.removeEventListener('resize', resizeHandler)
    }
  }, [])

  useLayoutEffect(() => {
    const codeInput = codeRef.current

    if (!codeInput) return

    const handleFocus = () => {
      setKeyboardActivateStatus('by-bottom')
    }

    const handleBlur = () => {
      setTimeout(() => {
        setKeyboardActivateStatus('idle')
      }, 0)
    }

    codeInput.addEventListener('focus', handleFocus)
    codeInput.addEventListener('blur', handleBlur)

    return () => {
      if (!codeInput) return
      codeInput.removeEventListener('focus', handleFocus)
      codeInput.removeEventListener('blur', handleBlur)
    }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const isKeyboardActivated = window.scrollY !== 0

      // public 디렉터리의 index.html 파일에 style 로 정의해두었다.
      const safeAreaEnv = getComputedStyle(document.documentElement).getPropertyValue('--sat')
      const safeAreaConstant = getComputedStyle(document.documentElement).getPropertyValue('--sat')

      // iOS 11.2 버전 이상에서는 ##px 이라고 표시한다.
      if (safeAreaEnv.includes('px')) {
        setTranslateY(
          isKeyboardActivated
            ? keyboardHeight - window.scrollY - parseInt(safeAreaEnv.slice(0, -2)) + __DANGEROUS_TRANSLATE_Y_PX
            : 0
        )
        return
      }

      // iOS 11.0 버전 이하에서는 ##px 이라고 표시한다.
      if (safeAreaConstant.includes('px')) {
        setTranslateY(
          isKeyboardActivated
            ? keyboardHeight - window.scrollY - parseInt(safeAreaConstant.slice(0, -2)) + __DANGEROUS_TRANSLATE_Y_PX
            : 0
        )
        return
      }

      // fallback 코드이지만 호출되면 스타일이 어색하나 버튼 클릭이 불가능하지 않음
      setTranslateY(isKeyboardActivated ? keyboardHeight - window.scrollY : 0)
    }

    handleScroll()

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [keyboardHeight])

  useEffect(() => {
    if (globalTransitionState === 'idle' && (props as { os: 'Android' | 'Cupertino' }).os === 'Android') {
      focusOn(() => emailRef.current, {
        delay: 100,
        type: 'email',
      })
    }
  }, [globalTransitionState, props])

  useEffect(() => {
    if (codeAsyncState.fulfilled) {
      focusOn(() => codeRef.current, {
        type: 'number',
      })
    }
  }, [codeAsyncState])

  useEffect(() => {
    return () => {
      dispatch(resetEmailState)
    }
  }, [dispatch])

  const validateEmail = (email: string) => {
    let error

    if (!validateEmailAddress(email)) {
      error = t('my_account.email.message.error_email')
    } else {
      dispatch(resetEmailState())
    }

    formik.setFieldError('email', error)
    return error
  }

  const validateCode = (code: string) => {
    let error

    if (!validateEmailValidationCode(code)) {
      error = t('my_account.email.message.error_code')
    }

    formik.setFieldError('code', error)
    return error
  }

  const handleReqCodeClick = () => {
    if (!formik.dirty || formik.errors.email) {
      return
    }

    if (emailInfo.isCodeSent && !isEnd) {
      bridge.openToast({ toast: { body: t('common.retry_auth_forbidden') } })
      return
    }

    emailInfo.isCodeSent && (timerRef.current as any).startTimer(60 * 5)
    dispatch(enhancedReqEmailCode({ email: formik.values.email }))
    triggerTimer(10)
  }

  const handleValidateCodeClick = () => {
    if (!emailInfo.isCodeSent) {
      return
    }

    const error = validateCode(formik.values.code)

    if (error) {
      return
    }

    dispatch(enhancedReqUpdateEmail({ email: formik.values.email, code: formik.values.code }))
  }

  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (emailInfo.isCodeSent) {
      triggerTimer(0)
    }

    validateEmail(e.target.value)
    formik.handleChange(e)
  }

  return (
    <AppScreen
      appBar={{
        title: t('my_account.email.title'),
        backButton: {
          renderIcon: () => <IconBack />,
          onClick: () => {},
        },
      }}>
      <CustomPageContainer
        height={keyboardActivateStatus === 'by-top' && translateY === 0 ? viewportHeight : -1}
        os={(props as { os: 'Android' | 'Cupertino' }).os}>
        <Container
          height={keyboardActivateStatus === 'by-top' && translateY === 0 ? viewportHeight : -1}
          os={(props as { os: 'Android' | 'Cupertino' }).os}>
          {!emailInfo.isCodeSent && (
            <Description>
              <DescriptionText>{t('my_account.email.description')}</DescriptionText>
              <GuardImage src={decoratorEmail} />
            </Description>
          )}
          <form onSubmit={formik.handleSubmit}>
            <InputField
              ref={emailRef}
              name="email"
              type="email"
              placeholder={t('my_account.email.placeholder.email')}
              onChange={handleEmailChange}
              value={formik.values.email}
              invalid={!!emailInfo.emailCodeMessage}
              disabled={emailInfo.isCodeSent}
            />
            <ErrorMessage>{emailInfo.emailCodeMessage}</ErrorMessage>
            {emailInfo.isCodeSent && (
              <SubmitButton
                onClick={handleReqCodeClick}
                disabled={!formik.dirty || !!formik.errors.email || codeAsyncState.pending}
                pending={emailInfo.isCodeSent}>
                {emailInfo.isCodeSent ? (
                  <>
                    {t('my_account.email.button.retry_req_code')} &nbsp;
                    <span>(</span>
                    <Timer ref={timerRef} initialDuration={60 * 5} />
                    <span>)</span>
                  </>
                ) : (
                  t('my_account.email.button.req_code')
                )}
              </SubmitButton>
            )}

            {!emailInfo.isCodeSent && (
              <LinkContainer>
                <Link href={emailGuideFaqScheme}>{t('my_account.email.message.want_more_info')}</Link>
              </LinkContainer>
            )}

            <InputField
              display={emailInfo.isCodeSent ? 'initial' : 'none'}
              name="code"
              type="text"
              pattern="[0-9]*"
              inputMode="numeric"
              placeholder={t('my_account.email.placeholder.code')}
              onChange={formik.handleChange}
              value={formik.values.code}
              ref={codeRef}
              invalid={!!(formik.errors.code || emailInfo.updateEmailMessage)}
            />
            {emailInfo.isCodeSent && (
              <>
                {!(formik.errors.code || emailInfo.updateEmailMessage) ? (
                  <HelperMessage>{t('my_account.email.message.helper')}</HelperMessage>
                ) : (
                  <ErrorMessage>{formik.errors.code || emailInfo.updateEmailMessage}</ErrorMessage>
                )}
                <LinkContainer>
                  <Link href={authCodeFaqScheme}>{t('my_account.email.message.cant_get_code')}</Link>
                </LinkContainer>
              </>
            )}
          </form>
        </Container>
        {emailInfo.isCodeSent ? (
          <BottomButton
            os={(props as { os: 'Android' | 'Cupertino' }).os}
            isKeyboardActivated={keyboardActivateStatus !== 'idle'}
            translateY={translateY}
            onClick={handleValidateCodeClick}
            disabled={!formik.values.code || formik.values.code === '' || emailAsyncState.pending}>
            {query.register ? t('my_account.email.button.register_email') : t('my_account.email.button.update_email')}
          </BottomButton>
        ) : (
          <BottomButton
            os={(props as { os: 'Android' | 'Cupertino' }).os}
            isKeyboardActivated={keyboardActivateStatus !== 'idle'}
            translateY={translateY}
            onClick={handleReqCodeClick}
            disabled={!formik.dirty || !!formik.errors.email || codeAsyncState.pending}
            pending={emailInfo.isCodeSent}>
            {t('my_account.email.button.req_code')}
          </BottomButton>
        )}

        {(codeAsyncState.pending || emailAsyncState.pending) && <Loader />}
      </CustomPageContainer>
    </AppScreen>
  )
}

export default withOS(EmailPage)

const Container = styled.div<{ height?: number; os?: 'Android' | 'Cupertino' }>`
  padding: 0 16px 16px 16px;
  display: flex;
  flex-direction: column;
  height: ${(props) => {
    // iOS 11.2 버전 이상: env(safe-area-inset-top)

    // android 에서는 safe-area 관련 css 명세가 없다.
    if (props.os === 'Android') {
      return `calc(100vh - ${NAVBAR_HEIGHT_REM} - ${BOTTOM_BUTTON_HEIGHT_PX})`
    }

    // native iOS 에서 safe-area 대응을 해주지 않으면서 이제 가상키보드가 없을 때 safe-area 의 상하 영역을 제거한 영역만큼만 height 로 설정한다.
    if (props.height === -1 && props.os === 'Cupertino') {
      return `calc(100vh - ${NAVBAR_HEIGHT_REM} - ${BOTTOM_BUTTON_HEIGHT_PX} - env(safe-area-inset-top) - env(safe-area-inset-bottom))`
    }

    // native iOS 에서 safe-area 대응을 해주지 않으면서 이제 가상키보드가 있을 때 safe-area 의 상단 영역을 제거한 영역만큼만 height 로 설정한다.
    return `calc(${props.height}px - ${NAVBAR_HEIGHT_REM} - ${BOTTOM_BUTTON_HEIGHT_PX} + ${__DANGEROUS_COVER_UNKNOWN_MARGIN_PX} - env(safe-area-inset-top))`
  }};
  height: ${(props) => {
    // iOS 11.0 버전 이하 fallback: constant(safe-area-inset-top)

    // android 에서는 safe-area 관련 css 명세가 없다.
    if (props.os === 'Android') {
      return `calc(100vh - ${NAVBAR_HEIGHT_REM} - ${BOTTOM_BUTTON_HEIGHT_PX})`
    }

    // native iOS 에서 safe-area 대응을 해주지 않으면서 이제 가상키보드가 없을 때 safe-area 의 상하 영역을 제거한 영역만큼만 height 로 설정한다.
    if (props.height === -1 && props.os === 'Cupertino') {
      return `calc(100vh - ${NAVBAR_HEIGHT_REM} - ${BOTTOM_BUTTON_HEIGHT_PX} - constant(safe-area-inset-top) - constant(safe-area-inset-bottom))`
    }

    // native iOS 에서 safe-area 대응을 해주지 않으면서 이제 가상키보드가 있을 때 safe-area 의 상단 영역을 제거한 영역만큼만 height 로 설정한다.
    return `calc(${props.height}px - ${NAVBAR_HEIGHT_REM} - ${BOTTOM_BUTTON_HEIGHT_PX} + ${__DANGEROUS_COVER_UNKNOWN_MARGIN_PX} - constant(safe-area-inset-top))`
  }};
`

const Description = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const GuardImage = styled.img`
  width: 83px;
  height: 96px;
  margin-top: 16px;
`

const DescriptionText = styled.div`
  ${body1};

  margin: 24px 16px 0 0;
`

const HelperMessage = styled.div`
  margin: 8px 0 0;
  font-size: 0.8125rem;
  color: ${vars.$scale.color.gray600};
`

const InputField = styled.input<{ invalid?: boolean; display?: 'none' | 'initial' }>`
  background: transparent;
  margin: 24px 0 0;
  padding: 12px 16px;
  border: 1px solid ${vars.$scale.color.gray400};
  border-radius: 6px;
  width: 100%;
  font-size: 1rem;
  display: ${(props) => props.display ?? 'initial'};

  ::placeholder {
    color: ${vars.$scale.color.gray500};
  }

  &:focus {
    border-color: ${vars.$scale.color.gray900};
  }

  ${(props) =>
    props.invalid &&
    css`
      border-color: ${vars.$scale.color.red800};
    `}
`

const ErrorMessage = styled.div`
  margin: 8px 0 0;
  font-size: 0.8125rem;
  color: ${vars.$scale.color.red800};
`

const SubmitButton = styled.div<{ disabled?: boolean; pending?: boolean }>`
  display: block;
  margin: 16px 0 0;
  padding: 10.5px 0;
  border-radius: 6px;
  font-size: 18px;
  font-weight: bold;
  border: ${(props) => (props.pending ? `1px solid ${vars.$scale.color.gray300}` : 'none')};
  background-color: ${(props) =>
    props.pending ? 'transparent' : props.disabled ? vars.$scale.color.gray300 : vars.$scale.color.carrot500};
  color: ${(props) =>
    props.disabled
      ? vars.$scale.color.gray500
      : props.pending
      ? vars.$scale.color.gray900
      : vars.$static.color.staticWhite};
  text-align: center;
`

const LinkContainer = styled.div`
  margin: 24px 0 0;
  text-align: center;
`

const Link = styled.a`
  font-size: 0.875rem;
  color: ${vars.$scale.color.gray600};
  text-decoration: underline;
`

const CustomPageContainer = styled.div<{ height?: number; os?: 'Android' | 'Cupertino' }>`
  height: ${(props) => {
    // iOS 11.2 버전 이상: env(safe-area-inset-top)

    // android 에서는 safe-area 관련 css 명세가 없다.
    if (props.os === 'Android') {
      return `calc(100vh - ${NAVBAR_HEIGHT_REM})`
    }

    // native iOS 에서 safe-area 대응을 해주지 않으면서 이제 가상키보드가 없을 때 safe-area 의 상하 영역을 제거한 영역만큼만 height 로 설정한다.
    if (props.height === -1 && props.os === 'Cupertino') {
      return `calc(100vh - ${NAVBAR_HEIGHT_REM} - env(safe-area-inset-top) - env(safe-area-inset-bottom))`
    }

    // native iOS 에서 safe-area 대응을 해주지 않으면서 이제 가상키보드가 있을 때 safe-area 의 상단 영역을 제거한 영역만큼만 height 로 설정한다.
    return `calc(${props.height}px - ${NAVBAR_HEIGHT_REM} + ${__DANGEROUS_COVER_UNKNOWN_MARGIN_PX} - env(safe-area-inset-top))`
  }};
  height: ${(props) => {
    // iOS 11.0 버전 이하 fallback: constant(safe-area-inset-top)

    // android 에서는 safe-area 관련 css 명세가 없다.
    if (props.os === 'Android') {
      return `calc(100vh - ${NAVBAR_HEIGHT_REM})`
    }

    // native iOS 에서 safe-area 대응을 해주지 않으면서 이제 가상키보드가 없을 때 safe-area 의 상하 영역을 제거한 영역만큼만 height 로 설정한다.
    if (props.height === -1 && props.os === 'Cupertino') {
      return `calc(100vh - ${NAVBAR_HEIGHT_REM} - constant(safe-area-inset-top) - constant(safe-area-inset-bottom))`
    }

    // native iOS 에서 safe-area 대응을 해주지 않으면서 이제 가상키보드가 있을 때 safe-area 의 상단 영역을 제거한 영역만큼만 height 로 설정한다.
    return `calc(${props.height}px - ${NAVBAR_HEIGHT_REM} + ${__DANGEROUS_COVER_UNKNOWN_MARGIN_PX} - constant(safe-area-inset-top))`
  }};
`

const BottomButton = styled.div<{
  disabled?: boolean
  pending?: boolean
  translateY?: number
  isKeyboardActivated?: boolean
  os?: 'Android' | 'Cupertino'
}>`
  position: ${(props) => (props.os === 'Android' ? 'relative' : 'absolute')};
  width: ${(props) => {
    return props.isKeyboardActivated
      ? '100%'
      : `calc(100% - ${BOTTOM_BUTTON_HORIZONTAL_MARGIN_PX} - ${BOTTOM_BUTTON_HORIZONTAL_MARGIN_PX})`
  }};
  border-radius: ${(props) => (props.isKeyboardActivated ? 'none' : '6px')};
  left: ${(props) => (props.isKeyboardActivated ? '0' : BOTTOM_BUTTON_HORIZONTAL_MARGIN_PX)};
  bottom: ${(props) => (props.os === 'Android' && !props.isKeyboardActivated ? '12px' : 'none')};
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  border: ${(props) => (props.pending ? `1px solid ${vars.$scale.color.gray500}` : 'none')};
  background-color: ${(props) => (props.disabled ? vars.$scale.color.gray300 : vars.$scale.color.carrot500)};
  color: ${(props) =>
    props.disabled
      ? vars.$scale.color.gray500
      : props.pending
      ? vars.$scale.color.gray900
      : vars.$static.color.staticWhite};
  text-align: center;
  transform: ${(props) => `translateY(-${props.translateY}px)`};
`
