import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { vars } from '@seed-design/design-token'
import { AppScreen } from '@stackflow/plugin-basic-ui'
import type { ActivityComponentType } from '@stackflow/react'
import { AlertDialog, DialogContainer } from '@daangn/sprout-components-dialog'

import { userInfoSelector } from '@src/selectors/user'
import { PageContainer, MenuContainer } from '@src/components/base/Container'
import { SectionTitle, MenuTitle } from '@src/components/base/Title'
import { InlineTextButton } from '@src/components/base/Button'
import { formatPhoneNumber } from '@src/utils'
import { countrySelector } from '@src/selectors/app'
import { useFlow } from '@src/router/Routes'
import { initStateFromMyAccount } from '@src/ducks/user'
import { storage } from '@src/api'
import { getEmailDeleteBaseUrlByCountry, STORAGE_KEY_EMAIL } from '@src/constants/api'
import { fetchWithAuth } from '@src/api/fetchWithAuth'
import { bridge } from '@src/bridge'

const MyAccountGlobalPage: ActivityComponentType = () => {
  const { push } = useFlow()
  const country = useSelector(countrySelector)
  const userInfo = useSelector(userInfoSelector)
  const { t } = useTranslation()

  const [shouldEmailDeleteDialogueOpen, setShouldEmailDeleteDialogueOpen] = useState(false)

  const dispatch = useDispatch()

  const oauthProviderTitle = useMemo(() => {
    if (userInfo.oauthProvider === 'line') {
      return 'LINE Login'
    }

    if (userInfo.oauthProvider === 'apple') {
      return 'Apple Login'
    }

    return userInfo.oauthProvider
  }, [userInfo.oauthProvider])

  return (
    <AppScreen appBar={{ title: t('my_account.title') }}>
      <PageContainer>
        <section>
          <SectionTitle>{t('my_account.index.title.account_info')}</SectionTitle>
          {(country === 'CA' || country === 'US') && (
            <MenuContainer>
              <MenuTitle>{t('my_account.index.menu.email')}</MenuTitle>
              {userInfo.email && <MenuItem>{userInfo.email}</MenuItem>}
              <InlineTextButton
                onClick={() => {
                  // 이메일이 있는 경우 이메일 삭제 모달 띄우기
                  if (userInfo.email) {
                    setShouldEmailDeleteDialogueOpen(true)
                    return
                  }

                  // 이메일이 없는 경우 이메일 등록 화면 이동
                  push('EmailPage', { register: true })
                }}>
                {userInfo.email ? t('my_account.email.remove') : t('common.register')}
              </InlineTextButton>
            </MenuContainer>
          )}
          {country !== 'JP' && (
            <MenuContainer>
              <MenuTitle>{t('my_account.index.menu.phone_number')}</MenuTitle>
              {userInfo.phone && <MenuItem>{formatPhoneNumber(userInfo.phone, country)}</MenuItem>}
              <InlineTextButton onClick={() => push('PhonePage', {})}>{t('common.change')}</InlineTextButton>
            </MenuContainer>
          )}
          {country === 'JP' &&
            userInfo.isPrepared &&
            (userInfo.oauthProvider && userInfo.oauthProviderId ? (
              <MenuContainer>
                <MenuTitle>{oauthProviderTitle}</MenuTitle>
                <MenuItem>{userInfo.oauthProviderId}</MenuItem>
              </MenuContainer>
            ) : (
              <MenuContainer>
                <MenuTitle>{t('my_account.index.menu.phone_number')}</MenuTitle>
                {userInfo.phone && <MenuItem>{formatPhoneNumber(userInfo.phone, country)}</MenuItem>}
                <InlineTextButton onClick={() => push('PhonePage', {})}>{t('common.change')}</InlineTextButton>
              </MenuContainer>
            ))}
        </section>
        <DialogContainer
          onOutsideClick={() => {
            setShouldEmailDeleteDialogueOpen(false)
          }}>
          {shouldEmailDeleteDialogueOpen && (
            <AlertDialog
              description={t('my_account.email.dialogue.remove.description')}
              onPrimaryAction={async () => {
                try {
                  const res = await fetchWithAuth(
                    `${getEmailDeleteBaseUrlByCountry(country)}/user/v3/karrot-users/me/email-verification`,
                    {
                      method: 'DELETE',
                    }
                  )
                  if (res.status === 200) {
                    const email = storage.getItemFromLocal(STORAGE_KEY_EMAIL) || ''
                    dispatch(initStateFromMyAccount({ email }))
                    setShouldEmailDeleteDialogueOpen(false)
                    storage.removeItemAtLocal(STORAGE_KEY_EMAIL)

                    await bridge.openToast({ toast: { body: t('my_account.email.message.email_removed') } })
                    return
                  }

                  const errorData = await res.json()
                  await bridge.openToast({ toast: { body: errorData.message.text } })

                  setShouldEmailDeleteDialogueOpen(false)
                } catch (e) {
                  await bridge.openToast({ toast: { body: t('hoian.errors.unpredicted_error') } })
                  setShouldEmailDeleteDialogueOpen(false)
                }
              }}
              primaryActionLabel={t('common.remove')}
              secondaryActionLabel={t('common.cancel_modal')}
              onSecondaryAction={() => {
                setShouldEmailDeleteDialogueOpen(false)
              }}
              title={t('my_account.email.dialogue.remove.title')}
              maxWidth="80%"
              UNSAFE_style={{ whiteSpace: 'pre-line', wordBreak: 'normal' }}
            />
          )}
        </DialogContainer>
      </PageContainer>
    </AppScreen>
  )
}

export default MyAccountGlobalPage

const MenuItem = styled.div`
  margin: 8px 0 0;
  font-size: 0.875rem;
  color: ${vars.$scale.color.gray600};
`
