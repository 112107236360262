import React, { useEffect } from 'react'
import { karrotAnalyticsPlugin } from '@daangn/stackflow-plugin-karrot-analytics'
import { stackflow } from '@stackflow/react'
import { basicRendererPlugin } from '@stackflow/plugin-renderer-basic'
import { basicUIPlugin } from '@stackflow/plugin-basic-ui'
import { stackDepthChangePlugin } from '@stackflow/plugin-stack-depth-change'
import { vars } from '@seed-design/design-token'
import { historySyncPlugin } from '@stackflow/plugin-history-sync'
import { Dialog, TransferIdentificationProvider } from '@daangn/transfer-identification-sdk'
import { useDispatch, useSelector } from 'react-redux'

import ChangeRegionPage from '@src/pages/OtherSettings/ChangeRegion'
import NeedLoginPage from '@src/pages/NeedLogin'
import EmailPage from '@src/pages/MyAccount/Email'
import PhonePage from '@src/pages/MyAccount/Phone'
import IdentityVerificationMarketPage from '@src/pages/MyAccount/IdentityVerificationMarket'
import ServiceTermsPage from '@src/pages/MyAccount/ServiceTerms'
import OtherSettingsPage from '@src/pages/OtherSettings'
import ErrorPage from '@src/pages/Error'
import withAuth from '@src/hocs/withAuth'
import MyAccount from '@src/pages/MyAccount'
import { IS_DESKTOP } from '@src/config'
import { bridge } from '@src/bridge'
// eslint-disable-next-line import/order
import IconBack from '@src/components/svg/IconBack'

// eslint-disable-next-line no-restricted-imports
import { countrySelector } from '@src/selectors/app'
import { storage } from '@src/api'
import { STORAGE_KEY_EMAIL, STORAGE_KEY_IDENTITY_VERIFICATION, STORAGE_KEY_PHONE_NUMBER } from '@src/constants/api'
// eslint-disable-next-line import/order
import { initStateFromMyAccount } from '@src/ducks/user'

// eslint-disable-next-line no-restricted-imports
import IdentificationPageForPhoneNumberChange from '@src/pages/MyAccount/IdentificationPageForPhoneNumberChange'
import IdentificationPageForPhoneNumberComplete from '@src/pages/MyAccount/IdentificationPageForPhoneNumberComplete'

// eslint-disable-next-line no-restricted-imports
import ActivityBottomSheetOnlyTerms from '../components/ActivityBottomSheetOnlyTerms'

const platform = /iphone|ipad|ipod/i.test(window.navigator.userAgent.toLowerCase()) ? 'cupertino' : 'android'

export const { Stack, useFlow, actions, activities } = stackflow({
  transitionDuration: 350,
  activities: {
    MyAccountPage: withAuth(MyAccount),
    EmailPage: withAuth(EmailPage),
    PhonePage: withAuth(PhonePage),
    IdentityVerificationMarketPage: withAuth(IdentityVerificationMarketPage),
    IdentificationPageForPhoneNumberChange: withAuth(IdentificationPageForPhoneNumberChange),
    IdentificationPageForPhoneNumberComplete: withAuth(IdentificationPageForPhoneNumberComplete),
    ServiceTermsPage: withAuth(ServiceTermsPage),
    OtherSettingsPage: withAuth(OtherSettingsPage),
    ChangeRegionPage: withAuth(ChangeRegionPage),
    ChangeRegionPage2: withAuth(ChangeRegionPage),
    ActivityBottomSheetOnlyTerms: withAuth(ActivityBottomSheetOnlyTerms),
    ErrorPage,
    NeedLoginPage,
  },
  plugins: [
    basicRendererPlugin(),
    basicUIPlugin({
      // TODO: web 에서는 theme 이 web 으로 들어가서 인터렉션이 동작하지 않음
      theme: platform,
      backgroundColor: vars.$semantic.color.paperDefault,
      appBar: {
        textColor: vars.$scale.color.gray900,
        iconColor: vars.$scale.color.gray900,
        closeButton: {
          renderIcon: () => <IconBack />,
          onClick: () => {
            bridge.closeRouter({})
          },
        },
      },
    }),
    stackDepthChangePlugin({
      onInit: ({ depth }) => {
        if (IS_DESKTOP) {
          return
        }

        if (depth > 1) {
          bridge.styleCurrentRouter({
            router: { backSwipable: false, navbar: false, scrollable: false, enableSafeAreaInsets: true },
          })
        } else {
          bridge.styleCurrentRouter({
            router: { backSwipable: true, navbar: false, scrollable: false, enableSafeAreaInsets: true },
          })
        }
      },
      onDepthChanged: ({ depth }) => {
        if (IS_DESKTOP) {
          return
        }

        if (depth > 1) {
          bridge.styleCurrentRouter({
            router: { backSwipable: false, navbar: false, scrollable: false, enableSafeAreaInsets: true },
          })
        } else {
          bridge.styleCurrentRouter({
            router: { backSwipable: true, navbar: false, scrollable: false, enableSafeAreaInsets: true },
          })
        }
      },
    }),
    historySyncPlugin({
      useHash: false,
      routes: {
        MyAccountPage: ['/', '/my-account'],
        EmailPage: '/my-account/email',
        PhonePage: '/my-account/phone',
        IdentityVerificationMarketPage: '/my-account/identity-verification-market',
        IdentificationPageForPhoneNumberChange: '/my-account/identification-to-change-phone-number',
        IdentificationPageForPhoneNumberComplete: '/my-account/identification-to-change-phone-number-complete',
        ServiceTermsPage: '/my-account/service-terms',
        OtherSettingsPage: '/other-settings',
        ChangeRegionPage: '/other-settings/change-articles-region',
        ChangeRegionPage2:
          '/change-articles-region' /* ios 특정 앱 버전에서 /other-settings를 안넣고 호출하는 경우가 있어서 추가 라우팅 처리해줘요. */,
        ErrorPage: '/error',
        NeedLoginPage: '/need-login',
        ActivityBottomSheetOnlyTerms: '/activity-bottom-sheet-only-terms',
      },
      fallbackActivity: () => 'MyAccountPage',
    }),
    karrotAnalyticsPlugin({
      bridge,
      serviceName: 'common',
    }),
  ],
})

const Routes: React.FC = () => {
  const country = useSelector(countrySelector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (country !== 'KR') {
      const phone = storage.getItemFromLocal(STORAGE_KEY_PHONE_NUMBER) || ''
      const email = storage.getItemFromLocal(STORAGE_KEY_EMAIL) || ''
      dispatch(initStateFromMyAccount({ phone, email }))
      return
    }

    const email = storage.getItemFromLocal(STORAGE_KEY_EMAIL) || ''
    const phone = storage.getItemFromLocal(STORAGE_KEY_PHONE_NUMBER) || ''
    const identityVerification = storage.getItemFromLocal(STORAGE_KEY_IDENTITY_VERIFICATION) || {}

    const action = initStateFromMyAccount({ email, phone, identityVerification })
    dispatch({ ...action, payload: { ...action.payload, history, isRoot: true } })
  }, [country, dispatch])

  return (
    <TransferIdentificationProvider previous_screen_name="setting">
      <Stack />
      <Dialog />
    </TransferIdentificationProvider>
  )
}

export default Routes
