// api keys
import { IS_PROD } from '@src/config'

export const HOIAN_KEY = 'hoian'
export const KARROTAUTH_KEY = 'karrotauth'
export const DEFAULT_COUNTRY_KEY = 'KR'

// async state
export const PENDING = 'pending'
export const FULFILLED = 'fulfilled'
export const REJECTED = 'rejected'

// storage
export const SERVICE_KEY = 'UserSettings'
export const STORAGE_KEY_WEB_CRAWL_ALLOWED = 'webCrawlAllwed'
export const STORAGE_KEY_AD_ALLOWED = 'adAllowed'
export const STORAGE_KEY_EMAIL = 'email'
export const STORAGE_KEY_PHONE_NUMBER = 'phoneNumber'
export const STORAGE_KEY_IDENTITY_VERIFICATION = 'identityVerification'

export const TERMS_WEBVIEW_URL = IS_PROD ? 'https://terms.karrotwebview.com' : 'https://terms.alpha.karrotwebview.com'

export const IDENTIFICATION_BASE_URL = IS_PROD
  ? 'https://identification.kr.karrotmarket.com'
  : 'https://identification.alpha.kr.karrotmarket.com'

export const EMAIL_DELETE_BASE_URL = IS_PROD
  ? 'https://user.kr.karrotmarket.com'
  : 'https://user.alpha.kr.karrotmarket.com'

export const getEmailDeleteBaseUrlByCountry = (country: string) => {
  if (country === 'KR') {
    return EMAIL_DELETE_BASE_URL
  }

  return EMAIL_DELETE_BASE_URL.replace('kr', country.toLowerCase())
}
